<template>
  <div id="page-reports">
    <h5 class="m-4">{{ $t('PAGES.REPORTS.REPORTS') }}</h5>

    <b-modal size="lg" hide-footer ref="data-table-modal">
      <div v-if="table">
        <div class="card-body pt-0 table-responsive">
          <b-table
            id="data-table"
            :items="table"
            head-variant="light"
            sticky-header
            class="mh-100 table-striped"
          ></b-table>
        </div>
      </div>

      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.CONTINUE')"
        :cancel_text="$t('COMMON.CLOSE')"
        :spin="true"
        :hide_primary="true"
        @close="$refs['data-table-modal'].hide()"
        ref="dataTableSaveButton"
      />
    </b-modal>

    <b-modal
      ref="export-form"
      hide-footer
      >
        <div v-if="selected_item">

          <memlist-date-picker class="mt-4" v-if="selected_item.has_from_date" :title="$t('PAGES.REPORTS.FROM_DATE')" v-model="form.from_date"></memlist-date-picker>
          <memlist-date-picker class="mt-4" v-if="selected_item.has_to_date" :title="$t('PAGES.REPORTS.TO_DATE')" v-model="form.to_date"></memlist-date-picker>

          <memlist-select-input2
            v-if="selected_item.has_period_id"
            class="mt-4"
            id="period_option"
            name="period_option"
            :title="$t('PERIOD.PERIOD')"
            layout="vertical"
            validation_type="TEXT"
            v-model="form.period_id"
            :items="period_options"
            :required="false"
            :invalid_text="''"
            :info_text="''"
          />

          <memlist-select-input2
            v-if="selected_item.has_company_id"
            class="mt-4"
            id="company_option"
            name="company_option"
            :title="$t('COMPANY.COMPANY')"
            layout="vertical"
            validation_type="TEXT"
            v-model="form.company_id"
            :items="company_options"
            :required="false"
            :invalid_text="''"
            :info_text="''"
          />
        </div>

        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-4"
          @click.prevent="continue_clicked"
          >{{ $t('COMMON.CONTINUE') }}</a
        >
    </b-modal>
    <CustomReportEditorModal 
      ref="editor"
      :id="selected_id"
      @created="created_report"
      @updated="updated_report"
    />

    <!--begin::Advance Table Widget 9-->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column pl-2">
          <!-- <span class="card-label font-weight-bolder text-dark">Alla Kategorier</span> -->
        </h3>
        <div class="card-toolbar">
          <a
            v-if="isTHS"
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="create_custom_report_clicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('REPORT.CREATE_CUSTOM') }}</a
          >

        </div>
      </div>
      
      <div class="card-body pt-0 table-responsive">
        <b-table
          id="reports-table"
          :fields="fields"
          :items="custom_reports"
          head-variant="light"
          sticky-header
          class="mh-100 table-striped"
        >
          <template #cell(name)="row">
            <div v-if="row.item.script === 'UNG1'">
              <ExportUngReport1Button />
            </div>
            <div v-if="row.item.script === 'UNG2'">
              <ExportUngReport2Button />
            </div>
            <div v-if="row.item.script === 'UNG3'">
              <ExportUngReport3Button />
            </div>
            <div v-if="row.item.script === 'SALES'">
              <ExportSalesButton />
            </div>
            <div v-if="row.item.script === 'REGMEMBERS'">
              <ExportRegisteredMembersMonthButton />
            </div>
            <div v-if="row.item.script === 'SOLD_MEMBERSHIPS_SUMMARY'">
              {{ row.item.name }}
            </div>
            <div v-if="row.item.script === 'SOLD_MEMBERSHIPS_SECTION'">
              {{ row.item.name }}
            </div>
            <div v-if="row.item.script === 'NONE'">
              {{ row.item.name }}
            </div>
          </template>
          <template #cell(id)="row">
            <div class="justify-content-end d-flex">
              <div class="d-flex justify-content-center mb-3" v-if="downloading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <a
                v-else-if="row.item.script === 'NONE' || row.item.script === 'SOLD_MEMBERSHIPS_SUMMARY' || row.item.script === 'SOLD_MEMBERSHIPS_SECTION'"
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="export_custom_report(row.item.id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
                </span>
              </a>
              
              <a v-if="row.item.script === 'NONE'" href="#" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="show_table_clicked(row.item.id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/table-solid.svg" />
                </span>
              </a>

              <!--<a href="#" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="show_graph_clicked(row.item.id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/chart-simple-solid.svg" />
                </span>
              </a>-->

              <a v-if="isTHS" href="#" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_item_clicked(row.item.id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Write.svg" />
                </span>
              </a>

              <a v-if="isTHS" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_item_clicked(row.item.id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Trash.svg" />
                </span>
              </a>
              
            </div>
          </template>
        </b-table>
      </div>
        
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import ExportSalesButton from '@/view/components/ExportSalesButton.vue';
import ExportUngReport1Button from '@/view/components/ExportUngReport1Button.vue';
import ExportUngReport2Button from '@/view/components/ExportUngReport2Button.vue';
import ExportUngReport3Button from '@/view/components/ExportUngReport3Button.vue';
import CustomReportEditorModal from '@/view/components/CustomReportEditorModal.vue';
import ExportRegisteredMembersMonthButton from '@/view/components/ExportRegisteredMembersMonthButton.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {
  name: 'ReportsPage',
  components: {
    ExportSalesButton,
    ExportUngReport1Button,
    ExportUngReport2Button,
    ExportUngReport3Button,
    ExportRegisteredMembersMonthButton,
    CustomReportEditorModal,
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  computed: {
    period_options() {
      return this.periods.map(item => ({ text: item.name, value: item.id }));
    },
    company_options() {
      return this.companies.map(item => ({ text: item.name, value: item.company_id }));
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS', 'customer', 'periods', 'companies']),
  },
  mounted() {
    this.load_custom_reports();

    this.form.from_date = dayjs().startOf('year').format('YYYY-MM-DD');
    this.form.to_date = dayjs().endOf('year').format('YYYY-MM-DD');
    
  },
  data() {
    return {
      state: '',
      table: [],
      selected_item: null,
      fields: [
        { key: 'name', label: this.$t('REPORT.CUSTOM_NAME') },
        { key: 'id', label: '' },
      ],
      form: {
        from_date: null,
        to_date: null,
        period_id: null,
        company_id: null,
      },
      selected_id: null,
      downloading: false,
      custom_reports: [],
    };
  },
  watch: {
    currentCompanyId: {
      deep: true,
      handler(val) {
        this.form.company_id = val;
      },
      immediate: true
    },
    currentPeriodId: {
      deep: true,
      handler(val) {
        this.form.period_id = val;
      },
      immediate: true
    },
  },
  methods: {

    async show_table_clicked(id) {
      const item = this.custom_reports.find(item => item.id === id);

      if (!item) {
        return;
      }

      this.state = 'table';
      this.selected_item = item;

      if (this.selected_item.has_from_date || this.selected_item.has_to_date || this.selected_item.has_period_id || this.selected_item.has_company_id) {
        this.$refs['export-form'].show();

        return;
      }

      this.table_data(id);
    },

    async table_data(id) {
      const res = await axios.post(`/custom_report/${id}/data`, this.form);

      if (res.status === 200) {
        this.table = res.data;
        
        this.$refs['data-table-modal'].show();
      }
    },

    async show_graph_clicked(id) {},

    continue_clicked() {

      if (this.state === 'file') {
        this.download_report(this.selected_item.id);
      }
      else if(this.state === 'table') {
        this.table_data(this.selected_item.id);
      }
      
      this.$refs['export-form'].hide();
    },

    async load_custom_reports() {
      const res = await axios.get(`/custom_report/customer/${this.customer.id}`);

      if (res.status === 200) {
        this.custom_reports = res.data;
      }
    },

    create_custom_report_clicked() {
      this.selected_id = null;
      this.$refs['editor'].show();
    },

    created_report(report) {
      this.custom_reports.push(report);
    },

    updated_report(report) {
      
    },

    async delete_item_clicked(id) {
      const res = await axios.delete(`/custom_report/${id}`);

      if (res.status === 204) {
        this.custom_reports = this.custom_reports.filter(item => item.id !== id);
        this.toastr('success', this.$t('COMMON.OK'), this.$t('REPORT.DELETED'));
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('REPORT.UNABLE_DELETE'));
      }
      
    },

    select_item_clicked(id) {
      this.selected_id = id;
      this.$refs['editor'].show();
    },

    async download_report(id) {
      
      try {
        
        this.downloading = true;

        const res = await axios.post(`/custom_report/${id}/xlsx`, this.form);

        if (res.status === 201) {
          this.download_file(res.data);
        }
        else {
          this.downloading = false;
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
        }
      }
      catch (err) {
        console.error('export_custom_report error', err);
        this.downloading = false;
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
      }
    },

    async export_custom_report(id) {

      const item = this.custom_reports.find(item => item.id === id);

      if (!item) {
        return;
      }

      this.state = 'file';
      this.selected_item = item;

      if (this.selected_item.has_from_date || this.selected_item.has_to_date || this.selected_item.has_period_id || this.selected_item.has_company_id) {
        this.$refs['export-form'].show();

        return;
      }

      this.download_report(id);
    },

    async download_file(file) {

      try {
        downloadWithAxios(get_base_url() + `/dlfile/${file.linkstr}`, file.name);
      }
      catch (err) {
        console.error('download_file', err);
        
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
      }

      setTimeout(() => {
        this.downloading = false;
      }, 1000);
      
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.page {
  background-color: white;
}

.h-50 {
  height: 50px;
  margin-top: 32px !important;
}

</style>